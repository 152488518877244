<template>
  <div>
    <div class="aboutHeader">
      <h3>
        16.4ft LED USB Car Interior Decor Atmosphere Wire Strip Light Lamp
        Accessories.
      </h3>
      <div class="aboutHeader-link">
        <p>
          <router-link style="text-decoration: none" to="/">
            <v-icon style="margin-top: -4px"> mdi-home </v-icon>
          </router-link>
          <router-link
            style="text-decoration: none; color: black"
            to="/homeDecor"
          >
            > Home Decor
          </router-link>
          <router-link
            to="/bikeStand"
            style="text-decoration: none; color: black"
          >
            > 16.4ft LED USB Car Interior Decor Atmosphere Wire Strip Light Lamp
            Accessories.
          </router-link>
        </p>
      </div>
    </div>

    <div class="productMain">
      <div class="productImage">
        <carousel :per-page="1">
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/ledUsbCar/1.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/ledUsbCar/2.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/ledUsbCar/3.jpg"
              alt=""
            />
          </slide>

          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/ledUsbCar/4.jpg"
              alt=""
            />
          </slide>
        </carousel>
      </div>

      <div class="productContent">
        <h2 class="pTitle">
          16.4ft LED USB Car Interior Decor Atmosphere Wire Strip Light Lamp
          Accessories.
        </h2>
        <h2 style="color: #38872c">$30.00</h2>
        <p>5m Flexible EL Wire; flexible and water resistant.</p>
        <p>
          Easy To Install: Cut off the excess part according to your own
          suitable length, then seal the cable end.
        </p>
        <p>
          Multiple Uses: Good for parties Festive decoration, camping bar, DIY
          and so on.
        </p>
        <p>
          Durable: Low-power electrode wire practical and safe, waterproof, no
          radiation, Life of up to 6000 hours.
        </p>
        <p>
          Soft and Light: High Brightness and less power consumption, Not
          glaring and very fashionable.
        </p>
        <p>Specially designed for EL Lamp and EL wire.</p>
        <p>Run with any USB ports.</p>
        <p v-if="this.inStock >= 1" style="color: #38872c">
          {{ inStock }} in stock
        </p>
        <p v-else style="color: red; font-weight: bold">out of stock</p>
        <div v-if="this.inStock >= 1" class="counter">
          <div class="nCounter" @click="nCount">
            <v-icon> mdi-minus </v-icon>
          </div>
          <div class="count">{{ count }}</div>
          <div class="pCounter" @click="pCount">
            <v-icon> mdi-plus </v-icon>
          </div>
        </div>
        <button v-if="this.inStock >= 1" @click="addToCart">Add to cart</button>
      </div>
    </div>

    <div class="deatailsHeader">
      <p>DESCRIPTION</p>
    </div>

    <div class="deatailsContent1">
      <h2>Portable Bike Parking Rack- Black</h2>
      <p>
        EL wire is a thin copper wire coated in a phosphor which glows a 360
        degree unbroken light when an alternating current is applied to it.
      </p>
      <p>
        It is bendable as a thin neon tube which makes it ideal for flexible
        decoration.
      </p>
      <p>
        It is great for car decoration, parties, camping, bar decoration and so
        on.
      </p>
      <p>Light your life and have fun!</p>

      <p style="font-size: 24px; color: black">Features:</p>
      <p>5m Flexible EL Wire; flexible and water resistant.</p>
      <p>
        Easy To Install: Cut off the excess part according to your own suitable
        length, then seal the cable end.
      </p>
      <p>
        Multiple Uses: Good for parties Festive decoration, camping bar, DIY and
        so on.
      </p>
      <p>
        Durable: Low-power electrode wire practical and safe, waterproof, no
        radiation, Life of up to 6000 hours.
      </p>
      <p>
        Soft and Light: High Brightness and less power consumption, Not glaring
        and very fashionable.
      </p>
      <p>Specially designed for EL Lamp and EL wire.</p>
      <p>Run with any USB ports.</p>

      <p style="font-size: 24px; color: black">USB:</p>
      <p>Can offer 360 degrees of illumination</p>
      <p>Energy saving, long life time</p>
      <p>Input voltage: DC 5V 1A</p>
      <p>Life time: Up to 6,000 hours</p>
      <p>EL light length: 5M</p>
      <p>Color: Blue</p>

      <p style="font-size: 24px; color: black">Package Contents:</p>
      <p>1 x Cool Light Strip</p>
      <p>1 x USB Inverter</p>
    </div>

    <div class="headerTitle">
      <h2>
        Related <span style="color: #38872c; font-weight: 700">Products</span>
      </h2>

      <div class="hr">
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 100px" />
      </div>
    </div>

    <div class="top">
      <div class="topBoxes">
        <div class="topContent-1">
          <div @click="nightStar" class="topBox-1 nightStar">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>New LED Night Star Sky Projector Light Lamp</h2>
            <p><span>$50.00</span> $30.00</p>
          </div>

          <div @click="rgb" class="topBox-3 rgb">
            <h4 style="background-color: red; padding-left: 10px">
              Out of Stock
            </h4>
            <h2>Rgb led string room lights decor tree lamp for bedroom</h2>
            <p>$40.00</p>
          </div>

          <div @click="wallLamp" class="topBox-3 wallLamp">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>2PCS Solar LED Light Outdoor Wall Lamp Garden Light</h2>
            <p>$25.00</p>
          </div>
        </div>
      </div>
    </div>

    <ErrorDialog v-model="error" />
  </div>
</template>

<script>
import ErrorDialog from "../../components/ErrorDialog.vue";
export default {
  data() {
    return {
      count: 1,
      carts: {},
      error: false,
      inStock: 73,
    };
  },
  methods: {
    nightStar() {
      this.$router.push("/nightStar");
    },
    rgb() {
      this.$router.push("/rgb");
    },
    wallLamp() {
      this.$router.push("/wallLamp");
    },
    pCount() {
      if (this.inStock != 0) {
        this.count += 1;
        this.inStock -= 1;
      }
    },
    nCount() {
      if (this.count > 1) {
        this.count -= 1;
        this.inStock += 1;
      }
    },
    addToCart() {
      const product = {
        id: "bikeStand",
        productTitle:
          "1-3 Bike Stand Bicycle Rack Storage Floor Parking Holder Cycling Portable Stands",
        price: 30.0,
        quantity: this.count,
        subTotal: this.count * 30.0,
        color: this.color,
      };

      this.carts[product.id] = product;
      localStorage.setItem("cart", JSON.stringify(this.carts));
      this.error = true;
      this.count = 1;
    },
  },
  mounted() {
    this.carts = JSON.parse(localStorage.getItem("cart"));
    if (!this.carts) this.carts = {};
  },
  components: { ErrorDialog },
};
</script>
