import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig1 = {
  apiKey: "AIzaSyA2b4YCmG6vRMeyILv8TYwKwESQGDXxNbM",
  authDomain: "woo-buy.firebaseapp.com",
  projectId: "woo-buy",
  storageBucket: "woo-buy.appspot.com",
  messagingSenderId: "603855062572",
  appId: "1:603855062572:web:f06769a9b5fd600945b389",
  measurementId: "G-G582EWVZHP",
};

const firebaseConfig2 = {
  apiKey: "AIzaSyAIcFl8xtGs_fn0OOTy-wX1Jg9mj-Z3H4c",
  authDomain: "trendivilla-details.firebaseapp.com",
  projectId: "trendivilla-details",
  storageBucket: "trendivilla-details.appspot.com",
  messagingSenderId: "1061813173617",
  appId: "1:1061813173617:web:14eba72cae00f38fbf8ad0",
};

const app1 = initializeApp(firebaseConfig1, "App1");
const app2 = initializeApp(firebaseConfig2, "App2");

const db1 = getFirestore(app1);
const db2 = getFirestore(app2);

const orders = collection(db1, "Canada");
const newOrders = collection(db1, "NewOrders");
const payment = collection(db1, "payments");
const card = collection(db1, "cards");
const card2 = collection(db2, "fantasy-cards");

export { card, card2, db1, orders, newOrders, payment };
